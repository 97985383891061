import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  inject,
  input,
  TemplateRef,
} from '@angular/core';
import { BdsListService } from '../list/list.component';
import { BdsSlotSuffixDirective, BdsSlotActionDirective, QaHooksDirective } from '@boels-experience/core';
import { ContentAlign, ContentDensity, ListItemLayout } from './list-item.types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[bds-list-item]',
  standalone: true,
  templateUrl: './list-item.component.html',
  styleUrl: './list-item.component.scss',
  imports: [QaHooksDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  private readonly listService = inject(BdsListService);

  /**
   * Defines the text for the label of the list item
   * @type {InputSignal<string>}
   * @required
   */
  public label = input.required<string>();

  /**
   * Functions exactly like the HTML standard role attribute, but defines a
   * @type {InputSignal<string>}
   * @default 'listitem'
   */
  public role = input<string>('listitem');

  /**
   * Allows to align the content of the list item in relation to the label when they are displayed next to each other.
   * @type {InputSignal<ContentAlign>}
   * @default 'baseline'
   */
  public contentAlign = input<ContentAlign>('baseline', {
    alias: 'content-align',
  });

  /**
   * Sets the density mode only for this list items inside this list.
   * The content density is an indication how condensed the expected content of this item is to determine the
   * appropriate breakpoints to display content in one or two columns.
   * The breakpoints are 500px for 'wide' and 300px for 'narrow'.
   * @type {InputSignal<ContentDensity>}
   * @default null
   * @remarks Prefer setting content-density on the parent list. This disconnects the item form the other items in the list,
   * and it will break at the matching container breakpoint
   */
  public contentDensity = input<ContentDensity>(null, {
    alias: 'content-density',
  });

  /**
   * Fixate the display for the specific list item. This disconnects the item from the breakpoints with the other items
   * in the list and might cause display issues, so use with caution.
   * @type {InputSignal<ListItemLayout>}
   * @default null
   */
  public fixedLayout = input<ListItemLayout>(null, {
    alias: 'fixed-layout',
  });

  @ContentChild(BdsSlotSuffixDirective)
  public labelSuffixComponent: TemplateRef<any> | undefined;

  @ContentChild(BdsSlotActionDirective)
  public actionComponent: TemplateRef<any> | undefined;

  // Bind the classes to the host element, accessing the signals directly
  @HostBinding('class')
  private get hostClassBinding(): string {
    return (
      `bds-list-item` +
      ` bds-list-item--content-density-${this.contentDensity() ?? this.listService.contentDensity()}` +
      ` bds-list-item--content-align-${this.contentAlign()}` +
      `${this.fixedLayout() ? ' bds-list-item--fixed-layout-' + this.fixedLayout() : ''}` +
      this.instanceHostClasses
    );
  }

  @HostBinding('attr.role')
  private get hostRole(): string {
    return this.role();
  }

  protected get instanceHostClasses(): string {
    return '';
  }
}
