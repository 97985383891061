<span class="bds-list-item__label -input" qa="bds-list-item-label">
  {{ label() }}
  @if (labelSuffixComponent) {
    <span class="bds-list-item__label-suffix -input">
      <ng-content select="[bds-slot-suffix]"></ng-content>
    </span>
  }
</span>
<div class="bds-list-item__content -input">
  @if (editActive()) {
    <span class="bds-list-item__value -input -edit" [formGroup]="form">
      <input #inputElement [formControlName]="'value'" (keyup)="onKeyup($event)" [attr.maxlength]="maxlength()" />
    </span>
  } @else {
    <ng-content></ng-content>
  }
</div>
<div class="bds-list-item__action -input">
  @if (editActive()) {
    <button
      bds-button
      shape="circle"
      (click)="submit()"
      [disabled]="form.invalid"
      [attr.aria-label]="'aria.buttonSubmit' | translate: { label: this.label() }"
    >
      <fa-icon [icon]="faCheck"></fa-icon>
    </button>
    <button
      bds-button
      shape="circle"
      (click)="cancel()"
      [attr.aria-label]="'aria.buttonCancel' | translate: { label: '' }"
    >
      <fa-icon [icon]="faXmark"></fa-icon>
    </button>
  } @else {
    <button
      bds-button
      shape="circle"
      (click)="edit()"
      [attr.aria-label]="'aria.buttonEdit' | translate: { label: this.label() }"
    >
      <fa-icon [icon]="faPencil"></fa-icon>
    </button>
  }
</div>
